import { PhoneIcon, MailIcon, IdentificationIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import Logo from "./logo";

function App() {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center p-4">
        <div className="w-64 max-w-full flex flex-col">
            <Logo />
        </div>
        <p className="pb-8 text-center text-xs">A software consultancy, est. 2012</p>
        <ul className="text-xs grid grid-rows-3 gap-2">
            <li className="flex flex-row gap-2 items-center font-bold"><PhoneIcon className="h-4 w-4 text-white"/><a href={"tel:+358504033332"}>+358 (0) 504033332</a></li>
            <li className="flex flex-row gap-2 items-center font-bold"><MailIcon className="h-4 w-4 text-white"/><a href={"mailto:info@mindmine.fi"}>info@mindmine.fi</a></li>
            <li className="flex flex-row gap-2 items-center"><IdentificationIcon className="h-4 w-4 text-gray-200"/>2258370-2</li>
            <li className="flex flex-row gap-2 items-center"><LocationMarkerIcon className="h-4 w-4 text-gray-200"/>Helsinki, Finland</li>
        </ul>
        <p className="p-6 text-center text-xs -ml-6">&copy; all rights reserved.</p>
    </div>
  );
}

export default App;
